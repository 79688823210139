export const footerToggle = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _footer_accordion() {
		// when a button with has_drop is hovered over, add the class active to the following ul
		const has_drop = document.querySelectorAll('.has_drop');
		
		// when has_drop is hovered over console.log('hovered');
		has_drop.forEach((el) => {
			el.addEventListener('click', () => {
				// get image inside button and rotate it
				const img = el.querySelector('img');
				if (img !== null) img.style.transform = 'rotate(90deg)';
				// get the .dropdown that is a sibling of the button
				const dropdown = document.querySelector('.dropdown'); 
				dropdown.style.display = 'block';
			});
		});
	}

	function _footer_drops() {
		let sections = document.querySelectorAll('.footer_links section');

		function toggleDropdown(ul) {
			ul.classList.toggle('active');
		}

		function handleDropdown(event) {
			let section = event.currentTarget;
			let ul = section.querySelector('ul');
			let img = section.querySelector('span img');

			let screenWidth = window.innerWidth;
			if (screenWidth <= 820) {
				toggleDropdown(ul);

				//Rotate the arrow no matter the orientation
				let rotationAngle = parseInt(img.getAttribute('data-rotation') || 0, 10);
				rotationAngle += 180;
				img.style.transform = `rotate(${rotationAngle}deg)`;
				img.setAttribute('data-rotation', rotationAngle);
			}
		}

		sections.forEach(section => {
			section.addEventListener('click', handleDropdown);
		});
	}

	my.init = function(){
		_addDoubleClick();
		_footer_accordion();
		_footer_drops();
	};

	return my;
})();
