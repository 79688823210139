export const marketingToggles = (function() {
	let my = {};
	function _buttonToggles() {
		if (document.querySelector('#marketing_toggles')) {
			const buttons = document.querySelectorAll('.toggle');
			const blocks = document.querySelectorAll('#marketing_items > div');

			buttons.forEach(button => {
				button.addEventListener('click', function(event) {
					const targetId = event.target.id;

					// Check if the clicked button is already active
					const isActive = event.target.classList.contains('active');

					if (isActive) return;

					// Remove 'active' class from all buttons
					buttons.forEach(btn => {
						btn.classList.remove('active');
					});

					// Add 'active' class to the clicked button if it wasn't already active
					if (!isActive) {
						event.target.classList.add('active');
					}

					// Toggle 'active' class for blocks based on the clicked button
					blocks.forEach(block => {
						if (block.classList.contains(targetId) && !isActive) {
							block.classList.add('active');
						} else {
							block.classList.remove('active');
						}
					});
				});
			});
		}
	}

	my.init = function() {
		_buttonToggles();
	};

	return my;
})();
