export const whyToggle = (function() {
	let my = {};

	function _buttonToggles() {
		if (document.querySelector('#home_why')) {
			const buttons = document.querySelectorAll('#select_items button');
			const articles = document.querySelectorAll('#why_container article');

			buttons.forEach((button) => {
				button.addEventListener('click', () => {
					if (!button.classList.contains('active')) {
						buttons.forEach((button) => {
							button.classList.remove('active');
						});
						button.classList.add('active');

						const id = button.id;
						articles.forEach((article) => {
							if (article.id === id) {
								if (!article.classList.contains('active')) {
									article.classList.add('active');
								}
							} else {
								if (article.classList.contains('active')) {
									article.classList.remove('active');
								}
							}
						});
					}
				});
			});
		}
	}

	function _handleCardGroups() {
		if (document.querySelector('#home_cards')) {
			const buttons = document.querySelectorAll('#card_options button');
			const cardInfoElements = document.querySelectorAll('.card_content');
			const allCardInfos = document.querySelectorAll('.card_info');
			const allCardSelects = document.querySelectorAll('.card_select');

			buttons.forEach(button => {
				button.addEventListener('click', function(event) {
					const clickedButton = event.target;
					if (!clickedButton.classList.contains('active')) {
						buttons.forEach(btn => {
							btn.classList.remove('active');
						});

						clickedButton.classList.add('active');

						const cardId = clickedButton.id;
						const correspondingCard = document.getElementById(cardId + '_card');
						const secondChild = correspondingCard.children[1];

						cardInfoElements.forEach(card => {
							card.classList.remove('active');
						});

						correspondingCard.classList.add('active');
						allCardInfos.forEach(sele => {
							sele.classList.remove('active');
						});
						secondChild.classList.add('active');
					}

					allCardSelects.forEach((selec, i) => {
						selec.classList.remove('active');
						if (i === 0 || i === 3) {
							if (!selec.classList.contains('active')) {
								selec.classList.add('active');
							}
						}
					});
				});
			});
		}
	}

	function _handleCardContent() {
		if (document.querySelector('.card_info')) {
			const allCardInfos = document.querySelectorAll('.card_info');
			const allCardSelects = document.querySelectorAll('.card_select');

			allCardSelects.forEach(card => {
				card.addEventListener('click', function(event) {
					const clickedCard = event.target;
					if (!clickedCard.classList.contains('active')) {
						allCardSelects.forEach(selec => {
							selec.classList.remove('active');
						});

						clickedCard.classList.add('active');

						const cardId = clickedCard.id;
						const correctSelect = Array.from(allCardInfos).find(sele => sele.id === cardId);

						allCardInfos.forEach(card => {
							if (card.id !== cardId) {
								card.classList.remove('active');
							}
						});

						if (correctSelect) {
							correctSelect.classList.add('active');
						}
					}
				});
			});
		}
	}

	my.init = function() {
		_buttonToggles();
		_handleCardGroups();
		_handleCardContent();
	};

	return my;

})();
