import { navToggle } from './header.js';
import { footerToggle } from './footer.js';

import { reviewsCaro } from './reviews.js';

import { contactSubmit } from './contact.js';
import { contactGlobal } from './global_contact.js';

import { ctaCaro } from './cta_caro.js';

import { marketingToggles } from './marketing.js';

import { whyToggle } from './home.js';

navToggle.init();
footerToggle.init();

reviewsCaro.init();

contactGlobal.init();

ctaCaro.init();

marketingToggles.init();

whyToggle.init();

if (document.getElementById("contact_form")) {
	contactSubmit();
}
